<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             content-class="rounded-xl border-0 shadow"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="title position-sticky d-flex justify-content-between align-items-center mb-2">
            <h1 class="h3 font-noto-serif-tc font-weight-bolder">
                Group Categories
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>
        <div>

        </div>
        <ul class="list-group shadow-sm">
            <category-item v-for="(category, ind) in categories"
                           v-bind:key="category.id"
                           v-bind:index="ind"
                           v-bind:categories="categories"
                           v-bind:category="category">
            </category-item>
        </ul>
        <div class="actions position-sticky py-2">
            <ul class="list-group shadow-sm">
                <li class="list-group-item bg-light shadow-sm p-2">
                    <form class="d-flex flex-nowrap"
                          v-on:submit.prevent.stop="addCategory">
                        <div class="d-flex flex-grow-1 w-100 pr-2">
                            <b-form-input class="input-color mr-1"
                                          size="sm"
                                          type="color"
                                          required
                                          v-model="newCategoryColor">
                            </b-form-input>
                            <b-form-input class="mr-1"
                                          size="sm"
                                          type="text"
                                          required
                                          v-model="newCategoryName">
                            </b-form-input>
                            <b-form-select class="select-region"
                                           size="sm"
                                           required
                                           v-model="newCategoryRegion">
                                <option v-for="{ _id, code, name, } in regions"
                                        v-bind:key="_id"
                                        v-bind:value="code">
                                    {{ name }}
                                </option>
                            </b-form-select>
                        </div>
                        <button class="btn btn-sm btn-success text-nowrap"
                                type="submit"
                                v-bind:class="{ 'btn-loading': isLoading, }">
                            ✨ Create
                        </button>
                    </form>
                </li>
            </ul>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BFormInput, BFormSelect, } from "bootstrap-vue";
export default {
    name: "ModalGroupCategories",
    components: {
        BModal, BFormInput, BFormSelect,
        CategoryItem: () => import("./ModalGroupCategories/CategoryItem"),
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isLoading: false,

            newCategoryColor: "#FFFFFF",
            newCategoryName: "",
            newCategoryRegion: "HKG",
        };
    },
    computed: {
        regions () {
            return this.$store.getters["region/itemsActive"];
        },
        categories () {
            return this.$store.getters["group/categories"];
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        reset () {
            this.newCategoryColor = "";
            this.newCategoryName = "";
        },
        async addCategory () {
            const { newCategoryColor, newCategoryName, newCategoryRegion, } = this;
            try {
                this.isLoading = true;
                await this.$store.dispatch(
                    "group/addCategory",
                    {
                        "order": this.categories.length,
                        "name": newCategoryName,
                        "color": newCategoryColor,
                        "region": newCategoryRegion,
                    }
                );
                this.reset();
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2.5px);
    z-index: 99;
}

.actions {
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2.5px);
    z-index: 99;
}

.input-color {
    max-width: 60px;
}

.select-region {
    max-width: 150px;
}
</style>
